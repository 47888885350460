.swal2-container {
    z-index: 2000;
  }

  .color-picker {
    height: 100%;
    position: relative;
    border: 1px solid #D8DDE1;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 6px;
    align-items: center;
  }
.color-picker-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
.MuiAutocomplete-tag {
  width:auto
}
.MuiAccordionSummary-content{
  margin: 0 !important;
}
.pageHeader{
  position: absolute;
  margin-top: -88px;
  z-index: 2000;
}
.BtnWidth{
  min-width: 250px !important;
}
.MuiButton-contained{
  text-transform: none !important;
  border-radius: 3px;
}
.MuiPaper-root{
  border-radius: 3px;
}
.MuiInputBase-root{
  border-radius: 3px;
}
.MuiButtonBase-root{
  border-radius: 3px;
}
.react-daterange-picker__wrapper{
  border-radius: 3px;
  min-height: 50px;
  border: 1px solid silver;
}